import {
  DataSnapshot,
  Unsubscribe,
  child,
  getDatabase,
  onChildAdded,
  orderByChild,
  query,
  ref,
  startAt
} from 'firebase/database'

import { getUserLogged } from './storage'

let unsubscribe: Unsubscribe

let lastReceivedNotificationID = ''

const parseJSON = (object: any) => {
  try {
    return JSON.parse(object)
  } catch {
    return null
  }
}

const subscribeNotifications = (context: any) => {
  const { subDomain, id: userId } = getUserLogged()

  if (!subDomain || !userId) {
    throw new Error('Subdomain/userId in subscribeNotifications was not found.')
  }

  const db = getDatabase()

  unsubscribe = onChildAdded(
    query(
      child(ref(db, subDomain), 'events'),
      orderByChild('timestamp'),
      startAt(Date.now())
    ),
    (snapShot: DataSnapshot) => {
      const { data } = snapShot.val()
      if (!data)
        throw new Error('Data object in subscribeNotifications was not found.')

      const parsedData = parseJSON(data)
      if (!parsedData)
        throw new Error(`Data object in subscribeNotifications it's not a JSON`)

      if (
        !lastReceivedNotificationID ||
        lastReceivedNotificationID !== parsedData?.id
      ) {
        lastReceivedNotificationID = parsedData.id

        if (parsedData?.idUser === userId) {
          context.dispatch('setUpdateNotificationList', true)
          context.dispatch('getNotificationCount')
        }
      }
    }
  )
}

const unsubscribeNotifications = () => unsubscribe && unsubscribe()

export { subscribeNotifications, unsubscribeNotifications }
