import Storage from '@octadesk-tech/storage'

import AdsNotifications from '@/modules/Chat/components/sidebar/components/ads-notifications'
import { channels } from '@/modules/Chat/components/sidebar/enums/chat-channels'
import { RoleTypes } from '@/modules/Chat/components/sidebar/enums/role-types'
import { getPhoneNumbers } from '@/modules/Chat/components/sidebar/services/api'
import * as botServices from '@/modules/Chat/components/sidebar/services/bots'
import * as services from '@/modules/Chat/components/sidebar/services/chats'
import { enums } from '@/modules/Chat/components/sidebar/services/enums'
import { inboxLists } from '@/modules/Chat/components/sidebar/services/inbox'
import ListFilters from '@/modules/Chat/components/sidebar/services/list-filters'
import byPeriod from '@/modules/Chat/components/sidebar/services/list-filters/by-period'
import {
  contactsPerPage,
  ContactsFilterTypes
} from '@/modules/Chat/components/sidebar/sidebar.enum.js'
import AnalyticsEvents from '@/modules/Chat/helpers/analytics-events'
import * as chatServices from '@/modules/Chat/services/chat'

import i18n from '@/common/i18n'

import privateRouter from '@/routes/router/private-router'

import * as types from './mutations-types'

export const setSelectedSidebarTab = (context, selectedSidebarTab) => {
  context.commit(types.SET_SELECTED_SIDEBAR_TAB, selectedSidebarTab)
}

export const setStatus = (context, status) =>
  context.commit(types.SET_STATUS, status)

export const setListFilter = (context, payload) => {
  if (payload) {
    context.commit(types.SET_CHATS_FILTER, payload)
  }

  if (!payload || !payload.preventDefault) {
    context.dispatch('setPage', 1)
    context.dispatch('getChats')
  }
}

export const loadCachedFilter = ({ commit }) => commit(types.LOAD_CACHED_FILTER)

const getSearchKey = ({ state }) =>
  [...state.status, state.page, JSON.stringify(state.filter)].join('')

let lastSearchKey = ''

// eslint-disable-next-line sonarjs/cognitive-complexity
export const getChats = async context => {
  const searchKey = getSearchKey(context)

  if (searchKey !== lastSearchKey || context.state.page === 1) {
    lastSearchKey = searchKey

    const isSearchTimeout = window.setTimeout(() => {
      context.commit(types.SET_IS_SEARCHING, 'searching')
    }, 500)

    if (isSearchTimeout) {
      window.clearTimeout(isSearchTimeout)
    }

    context.commit(types.FETCHING_CHAT_LIST, true)

    try {
      const { status, page, filter } = context.state

      const result = await services.fetchChats({
        status,
        page,
        filter
      })

      if (!result) {
        return
      }

      if (context.state.page === 1) {
        context.commit(types.SET_CHATS, result.rooms)
      } else {
        context.commit(types.APPEND_CHATS, result.rooms)
      }

      context.commit(types.SET_SEARCH_RESULT, result)

      context.commit(types.SET_IS_SEARCHING, 'ok')
    } catch (error) {
      const networkErrorMessage = 'Network Error'

      if (error.message !== networkErrorMessage) {
        if (i18n?.te(error.response?.data?.customCode)) {
          context.dispatch(
            'dispatchAlert',
            {
              body: i18n?.t(error.response?.data?.customCode),
              variant: 'danger'
            },
            { root: true }
          )
        }

        context.commit(types.SET_CHATS, [])

        context.commit(types.SET_IS_SEARCHING, 'error')

        if (error) {
          lastSearchKey = ''

          console.error('Error on getChats: ', error)
        }
      }
    } finally {
      context.commit(types.FETCHING_CHAT_LIST, false)
    }
  }
}

export const setLoadingMore = (context, toggle) =>
  context.commit(types.SET_IS_LOADING_MORE, toggle)

export const appendChat = (context, chat) =>
  context.commit(types.APPEND_CHAT, chat)

export const updateChats = (context, chats) =>
  context.commit(types.SET_CHATS, chats)

let updateListLoopTimeout

export const updateListLoop = async context => {
  if (updateListLoopTimeout) {
    clearTimeout(updateListLoopTimeout)
  }

  if (!context.getters.connectedSocket) {
    await context.dispatch('getChats')

    if (context.getters.responseServiceTimeout)
      context.dispatch('loadResponseServiceTimeoutCount')

    updateListLoopTimeout = setTimeout(
      () => context.dispatch('updateListLoop'),
      1000 * 30
    )
  }
}

export const setPage = (context, page) => context.commit(types.SET_PAGE, page)

export const increasePage = context =>
  context.commit(types.SET_PAGE, context.state.page + 1)

export const toggleFilterPanel = context =>
  context.commit(types.TOGGLE_FILTER_PANEL)

export const setTemporaryFilter = (context, filter) =>
  context.commit(types.SET_TEMPORARY_FILTER, filter)

export const applyTemporaryFilter = context => {
  const { temporaryFilter } = context.getters

  if (!temporaryFilter) {
    console.warn('No temporary filter found')
    return
  }

  context.commit(types.SET_SELECTED_FILTER, temporaryFilter)

  const {
    func,
    isAdmin,
    name,
    startDate,
    endDate,
    channel,
    searches,
    publicTags,
    domainFrom,
    organization,
    unreadOnly
  } = temporaryFilter

  const id = temporaryFilter?.id

  applySearchFilters(context, searches)

  applyDateFilters(context, startDate, endDate, name)

  applyFunctionFilter(context, func, id, isAdmin)

  applyChannelFilter(context, channel, domainFrom)

  applyTagFilters(context, publicTags)

  applyOrganizationFilter(context, organization)

  applyUnreadFilter(context, unreadOnly)

  context.dispatch('setListFilter')

  context.commit(types.UPDATE_SELECTED_FILTERS)
}

function applySearchFilters(context, searches) {
  if (Array.isArray(searches)) {
    searches.forEach(search => {
      const { property, title } = search

      context.dispatch(
        'trackEvent',
        {
          name: AnalyticsEvents.CONVERSATION_FILTERS,
          new_analytics: true,
          event_method: property,
          object_type: title
        },
        { root: true }
      )
    })

    context.dispatch('setListFilter', {
      filter: { filter: { searches } },
      preventDefault: true
    })
  }
}

function applyDateFilters(context, startDate, endDate, name) {
  if (startDate || endDate) {
    context.dispatch('setListFilter', {
      filter: byPeriod(startDate, endDate),
      preventDefault: true,
      name
    })
  }
}

function applyFunctionFilter(context, func, id, isAdmin) {
  if (func) {
    const isTimeExceededInbox =
      context.rootGetters['chat/currentInbox'].inboxName === 'timeExceeded'

    if (func === enums.inbox.mentioned) {
      id = context.getters.agent?.id
    }

    context.dispatch('setListFilter', {
      filter: ListFilters[func](id, isAdmin, isTimeExceededInbox),
      preventDefault: true
    })
  }
}

function applyChannelFilter(context, channel, domainFrom) {
  if (channel) {
    if (channel === 'all') {
      context.dispatch('setListFilter', {
        filter: { filter: {}, remove: ['channel'] },
        preventDefault: true
      })
    } else {
      const domainFromFilter =
        domainFrom?.length && channel === channels.whatsapp
          ? { domainFrom: { $in: domainFrom } }
          : null

      context.dispatch('setListFilter', {
        filter: {
          filter: { channel, ...domainFromFilter },
          remove: ['domainFrom']
        },
        preventDefault: true
      })
    }
  }
}

function applyTagFilters(context, publicTags) {
  let queryTags = {}

  if (publicTags && publicTags.length) {
    const publicTagsIds = publicTags.filter(t => t.id).map(tag => tag.id)

    const isEmptyTag = publicTags.filter(t => !t.id).length > 0

    const queryByIds = { 'publicTags._id': publicTagsIds }

    const queryByWithoutTag = {
      $or: [{ publicTags: { $eq: [] } }, { publicTags: { $exists: false } }]
    }

    if (publicTagsIds.length) {
      queryTags = queryByIds
    }

    if (isEmptyTag) {
      queryTags = queryByWithoutTag
    }

    if (publicTagsIds.length && isEmptyTag) {
      queryTags = {
        ...queryByWithoutTag,
        $or: [...queryByWithoutTag.$or, queryByIds]
      }
    }

    context.dispatch('setListFilter', {
      filter: { filter: queryTags, remove: ['publicTags._id'] },
      preventDefault: true
    })
  } else {
    context.dispatch('setListFilter', {
      filter: { filter: {}, remove: ['publicTags._id'] },
      preventDefault: true
    })
  }
}

function applyOrganizationFilter(context, organization) {
  if (organization && organization.length > 0) {
    context.dispatch('setListFilter', {
      filter: {
        filter: {
          'createdBy.organization.id': { $in: organization.map(o => o.id) }
        }
      },
      preventDefault: true
    })
  }
}

function applyUnreadFilter(context, unreadOnly) {
  if (unreadOnly) {
    context.dispatch('setListFilter', {
      filter: {
        filter: {
          lastMessageUserType: {
            $in: [
              enums.userTypes.system,
              enums.userTypes.customer,
              enums.userTypes.none
            ]
          }
        }
      },
      preventDefault: true
    })
  }
}

export const cleanFilters = context => {
  const { currentInbox, defaultFilter } = context.getters

  const initialFilters = Object.assign(defaultFilter, {})

  context.commit(types.CLEAN_FILTERS)

  context.commit(types.SET_CHATS_FILTER, initialFilters)

  context.dispatch('setCurrentInbox', currentInbox.inboxName)

  if (privateRouter.currentRoute.name !== 'chat-empty-conversation') {
    privateRouter.push({ name: 'chat-empty-conversation' })
  }
}

const hasMoreContacts = ({
  phoneContacts,
  totalFilteredContacts,
  contacts
}) => {
  if (!contacts.length) return false

  return phoneContacts.length < totalFilteredContacts
}

export const getPhoneContacts = async (context, payload) => {
  const { filter, type } = payload

  const { contacts, totalFilteredContacts } = await getPhoneNumbers(filter)

  const totalItens = filter.take + filter.skip

  const page = totalItens / contactsPerPage

  context.commit(types.SET_CONTACT_PAGE, page)

  let items = contacts

  const { phoneContacts } = context.getters

  if (type === ContactsFilterTypes.PAGINATION) {
    items = [...phoneContacts, ...items]
  }

  context.commit(types.SET_PHONE_CONTACTS, items)

  context.commit(
    types.SET_HAS_MORE_CONTATCS,
    hasMoreContacts({ phoneContacts, totalFilteredContacts, contacts })
  )
}

export const getWaitingCount = async context => {
  const waitingCount = await chatServices.getWaitingCount()

  context.commit(types.SET_WAITING_COUNT, waitingCount)

  return waitingCount
}

export const updateSidebarChat = (context, chat) => {
  context.commit(types.UPDATE_CHAT, chat)
}

export const updateChatListProperties = (context, { chatKey, props }) => {
  context.commit(types.UPDATE_CHAT_LIST_PROPERTIES, { chatKey, props })
}

export const getBots = async context => {
  const bots = (await botServices.getBots()) || []

  const botFluxes = bots.filter(flux => flux.ivr && flux.ivr.levels.length > 0)

  const chatbotFluxes = (await botServices.getChatBots()) || []

  const fluxes = [...botFluxes, ...chatbotFluxes]

  context.commit(types.SET_BOTS_FLUXES, fluxes)

  return fluxes
}

export const getChannelsUsage = async context => {
  const { company } = context.getters

  const storageKey = `chat_${company}_channels_usage`

  let cachedUsages = Storage.getItem(storageKey)

  if (!cachedUsages) {
    cachedUsages = await services.getChannelsUsage()

    Storage.setItem(storageKey, cachedUsages, 1800)
  }

  for (const channel of Object.keys(cachedUsages || {})) {
    context.commit(types.SET_HAS_CHAT_CHANNEL, {
      channel,
      value: cachedUsages[channel]
    })
  }
}

export const getUserLoggedAssignedCount = async ({ rootGetters, dispatch }) => {
  const { userLogged } = rootGetters

  if (userLogged) {
    const status = [0, 1, 3]

    const filter = {
      'agent._id': userLogged.id
    }

    const count = await chatServices.getCounter(
      status,
      undefined,
      undefined,
      undefined,
      filter
    )

    dispatch('setUserLoggedAssignedCount', count)
  }
}

export const setUserLoggedAssignedCount = ({ commit }, count) =>
  commit(types.SET_USER_LOGGED_ASSIGNED_COUNT, count)

export const setCurrentContact = ({ commit }, contact) =>
  commit(types.SET_CURRENT_CONTACT, contact)

export const getRoomList = (_, contactId) =>
  chatServices.getUserLastChat(contactId)

export const setCurrentInbox = async (context, inboxName) => {
  if (inboxName) {
    let id = null

    let isAdmin = null

    const { byAgent, unread, participation, timeExceeded, group } = enums.inbox

    if ([byAgent, unread, participation, timeExceeded].includes(inboxName)) {
      const { agent } = context.getters

      const { userLogged } = context.rootGetters

      if (agent?.id) {
        id = agent.id
      } else if (userLogged?.id) {
        id = userLogged.id
      }

      isAdmin = [RoleTypes.admin, RoleTypes.owner].includes(
        agent?.roleType || userLogged?.roleType
      )
    }

    const payload = {
      inboxName: inboxName,
      agentId: id
    }

    if (inboxName === group) {
      id = privateRouter.currentRoute.params.id
    }

    context.commit(types.SET_CURRENT_INBOX, payload)

    const filter = {
      name: i18n?.t(`chat.sidebar.inbox.${inboxName}`),
      func: inboxName,
      id,
      isAdmin
    }

    context.dispatch('setTemporaryFilter', filter)

    context.dispatch('setLoadingConversationList', true)

    await context.dispatch('applyTemporaryFilter', inboxName)

    const { selectedSidebarTab } = context.getters

    const statusList = selectedSidebarTab.id === 'open' ? 'open' : 'closed'

    context.dispatch('setStatus', inboxLists[payload.inboxName][statusList])

    context.dispatch('setConversationsListType', enums.listTypes[statusList])

    context.dispatch('setPage', 1)

    context.dispatch('closeCurrentChat')

    context.dispatch('setLoadingConversationList', false)
  }
}

export const setConversationsListType = (context, listType) => {
  context.commit(types.SET_CONVERSATIONS_LIST_TYPE, listType)
}

const verifyFeatureToggle = (features, featureName) => {
  if (!features) return false

  return features.some(f => f.code === featureName && f.active)
}

export const getExtraNotifications = context => {
  if (!AdsNotifications || !AdsNotifications.length) {
    return []
  }

  const { featuresToggle } = context.getters

  const activatedAds = []

  const canShowAds = ads => {
    if (!ads.enabled) {
      return false
    }

    const key = `${ads.notificationType}_close`

    const closedAds = Storage.getItem(key)

    if (closedAds) {
      return false
    }

    let validMandatoryFeatures = true

    let validForbiddenFeatures = true

    if (ads.mandatoryFeatures && ads.mandatoryFeatures.length) {
      validMandatoryFeatures = ads.mandatoryFeatures.every(feature =>
        verifyFeatureToggle(featuresToggle, feature)
      )
    }

    if (ads.forbiddenFeatures && ads.forbiddenFeatures.length) {
      validForbiddenFeatures = ads.forbiddenFeatures.every(
        feature => !verifyFeatureToggle(featuresToggle, feature)
      )
    }

    return validMandatoryFeatures && validForbiddenFeatures
  }

  AdsNotifications.forEach(ads => {
    if (canShowAds(ads)) {
      activatedAds.push({
        notificationType: ads.notificationType
      })
    }
  })

  return activatedAds
}

export const shouldCheckboxAllConversations = (
  { dispatch, getters },
  checked
) => {
  const chatList = [...getters.chats]

  chatList.forEach(chat => {
    chat.checked = checked
  })

  dispatch('updateChats', chatList)
}

export const updateCheckboxAllConversations = (
  { dispatch, getters },
  { checked, chatKey }
) => {
  const chatList = [...getters.chats]

  const indexChat = chatList.findIndex(chat => chat.key === chatKey)

  if (indexChat > -1) {
    chatList[indexChat].checked = checked

    dispatch('updateChats', chatList)
  }
}

export const setLoadingConversationList = ({ commit }, inProgress) => {
  commit(types.SET_LOADING_CONVERSATION_LIST, inProgress)
}

export const updatePhoneContactProperty = (context, { contactId, props }) => {
  context.commit(types.UPDATE_PHONE_CONTACT_PROPERTY, { contactId, props })
}

export const setQtdSelectedFilters = ({ commit }, qtd) => {
  localStorage.setItem(types.SET_QTD_SELECTED_FILTERS, qtd)

  commit(types.SET_QTD_SELECTED_FILTERS, qtd)
}

export const loadResponseServiceTimeoutCount = async context => {
  await services
    .fetchResponseServiceTimeoutCount()
    .then(count => context.dispatch('setResponseServiceTimeoutInbox', count))
}

export const setResponseServiceTimeoutInbox = async ({ commit }, inboxCount) =>
  commit(types.SET_RESPONSE_SERVICE_TIMEOUT_INBOX, inboxCount)
