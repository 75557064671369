import { ActionContext } from 'vuex'

import APIError from '@/common/errors/APIError'
import AuthError from '@/common/errors/AuthError'
import { analytics } from '@/common/plugins/Analytics'

import { subDomain, url } from '@octadesk-tech/services'
import Storage from '@octadesk-tech/storage'

import i18n from '@/common/i18n'

import octaConfig from '@/common/helpers/configs/octa-config'
import { ChatConnectionStatus } from '@/common/helpers/enums/chat-connection-status'
import { EVENTS } from '@/common/helpers/enums/events.enum'
import { STATUS_CODE } from '@/common/helpers/enums/http-status-codes'
import { PRODUCT_TARGETS } from '@/common/helpers/enums/product-targets.enum'
import { SignupUserTypes } from '@/common/helpers/enums/signup-user-types'
import {
  AccessRequest,
  AccessRequestResponse
} from '@/common/helpers/interfaces/access-request'
import { AuthPayload } from '@/common/helpers/interfaces/auth-payload'
import { ChangePasswordPayload } from '@/common/helpers/interfaces/change-password-payload'
import { HubspotFormPayload } from '@/common/helpers/interfaces/hubspot-form-payload'
import { InviteAgentPayload } from '@/common/helpers/interfaces/invite-agent-payload'
import { LinkedinPayload } from '@/common/helpers/interfaces/linkedin-payload'
import { NewPasswordPayload } from '@/common/helpers/interfaces/new-password-payload'
import { OctaConfig } from '@/common/helpers/interfaces/octa-config'
import { SignupPayload } from '@/common/helpers/interfaces/signup-payload'
import { SSOPayload } from '@/common/helpers/interfaces/sso-payload'
import { SubDomainStatus } from '@/common/helpers/interfaces/subdomain-status'
import { Tenant } from '@/common/helpers/interfaces/tenant'
import { UserLogged } from '@/common/helpers/interfaces/user-logged'
import { VerifyAccountPayload } from '@/common/helpers/interfaces/verify-account-payload'
import { getCookie, setCookie } from '@/common/helpers/utils/cookie'
import { getEnumKeyByEnumValue } from '@/common/helpers/utils/enum'
import { getParameterByName } from '@/common/helpers/utils/get-parameter-by-name'
import { getUtmValues } from '@/common/helpers/utils/utm'

import { ChatAgentsService } from '@/common/services/agent'
import { CompanyService } from '@/common/services/company'
import CoreService from '@/common/services/core'
import NucleusService from '@/common/services/nucleus'
import {
  clearCulture,
  getUserLogged,
  setCulture
} from '@/common/services/storage'
import UsersService from '@/common/services/users'

import store from '@/store'
import { AuthState, SSOTenant, RootState } from '@/store/interfaces'

import router from '@/routes/router'
import privateRouter from '@/routes/router/private-router'
import publicRouter from '@/routes/router/public-router'

import * as types from './mutations-types'

const objectToQueryString = (obj: { [key: string]: string }) => {
  const keyValuePairs = []

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const encodedKey = encodeURIComponent(key)

      const encodedValue = encodeURIComponent(obj[key])

      keyValuePairs.push(`${encodedKey}=${encodedValue}`)
    }
  }

  return keyValuePairs.join('&')
}

const resolveQueryStringParameter = (parameterName: string) => {
  const hasParam = getParameterByName(parameterName) === 'true'

  if (hasParam) {
    setCookie(parameterName, 'true')

    return true
  }

  return getCookie(parameterName) === 'true'
}

export const mockAuth = async (
  context: ActionContext<AuthState, RootState>
) => {
  if (resolveQueryStringParameter('mockAuth')) {
    const { auth } = await import('@/common/__mocks__/auth/prd.user')

    Storage.setItem('auth', auth)

    await context.dispatch('handleAuthResponse', auth)
  }
}

export const checkAuthState = async (
  context: ActionContext<AuthState, RootState>,
  path: string
) => {
  if (import.meta.env.MODE !== 'production') {
    await context.dispatch('mockAuth')
  }

  const { keepConnected } = context.state

  const auth = (
    keepConnected ? Storage.getItem('auth') : sessionStorage.getItem('auth')
  ) as AuthState

  if (!auth) {
    return false
  }

  const decryptedUserLogged = Storage.decrypt(
    decodeURIComponent(auth.userlogged as unknown as string)
  )

  const decodedUserToken = decodeURIComponent(auth.jwtoken as string)

  const loginType = Storage.getItem('loginType')

  if (loginType) {
    context.commit(types.SET_LOGIN_TYPE, loginType)
  }

  await context.dispatch('setAuth', auth)

  await context.dispatch('setUserLogged', decryptedUserLogged)

  await context.dispatch('setUserToken', decodedUserToken)

  await context.dispatch('getStatus', path)

  await context.dispatch('fetchUser', auth.octaAuthenticated?.userId)

  await context.dispatch('identifyAppcuesUser')

  await context.dispatch('getCoupon')

  await context.dispatch('getFeature')

  await context.dispatch('getPartner')

  await context.dispatch('getCurrentAgentAppsIds')

  await context.dispatch('getCompanyModel')

  await context.dispatch('getNotificationCount')

  context.dispatch('startMonitoringEvents')

  return true
}

export const clearAuthData = async (
  context: ActionContext<AuthState, RootState>
) => {
  await context.dispatch('clearUserLogged')

  await context.dispatch('clearUserToken')

  await context.dispatch('clearSubDomainStatus')

  await context.dispatch('setAuth', {
    access_token: undefined,
    octaAuthenticated: undefined,
    roles: undefined,
    tenants: undefined
  })

  context.commit(types.SET_TENANTS, undefined)

  clearCulture()
}

export const clearCredentials = async (
  context: ActionContext<AuthState, RootState>
) => {
  await context.dispatch('clearAuthData')

  setCookie('Vuetiful', 'false')

  Storage.clear()
}

export const logout = async (
  context: ActionContext<AuthState, RootState>,
  query: { [key: string]: string } = {}
) => {
  await context.dispatch('clearCredentials')

  context.dispatch('stopMonitoringEvents')

  router.replace({
    name: 'Public'
  })

  publicRouter.replace({
    name: 'Login',
    query
  })

  analytics(EVENTS.LOGOUT, {
    target: PRODUCT_TARGETS.NEW_PRODUCT
  })

  window.location.href = `/login?${objectToQueryString(query)}`
}

export const socketLogout = async (
  context: ActionContext<AuthState, RootState>,
  payload: any
) => {
  const { userLogged } = context.getters

  if (
    payload?.expiredSession ||
    payload?.forceLoggoutAll ||
    payload?.userId === userLogged?.id ||
    Object.keys(payload).length === 0
  ) {
    let logoutReason

    switch (true) {
      case payload?.expiredSession:
        logoutReason = 'expiredSession'

        break
      case payload?.forceLoggoutAll:
        logoutReason = 'forceLoggoutAll'

        break
      case payload?.userId === userLogged?.id:
        logoutReason = 'agentDisconnected'
        break
    }

    context.dispatch(
      'logout',
      logoutReason
        ? {
            logoutReason
          }
        : undefined
    )
  }
}

export const handleAuthResponse = async (
  context: ActionContext<AuthState, RootState>,
  authResponse: any
) => {
  if (authResponse.tenants) {
    context.commit(types.SET_TENANTS, authResponse.tenants)

    return authResponse.tenants
  }

  if (!authResponse.userlogged || !authResponse.jwtoken) {
    throw new Error('missing authentication data')
  }

  if (authResponse.octaAuthenticated?.subDomain) {
    subDomain.setSubDomain(authResponse.octaAuthenticated.subDomain)
  }

  if (authResponse.apis) {
    url.setAPIURLs(authResponse.apis)
  }

  await context.dispatch('setAuth', authResponse)

  await context.dispatch(
    'setUserLogged',
    Storage.decrypt(decodeURIComponent(authResponse.userlogged))
  )

  await context.dispatch('setUserToken', authResponse.jwtoken)

  await context.dispatch(
    'getIdOctadesk',
    authResponse.octaAuthenticated.subDomain
  )

  await context.dispatch('getStatus')

  await sendAppcuesData(context)

  analytics(
    'login',
    {
      userid: context.state.userlogged?.id,
      email: context.state.userlogged?.email
    },
    { engine: 'gtm' }
  )
}

export const setTenants = async (
  context: ActionContext<AuthState, RootState>,
  tenants: Array<Tenant>
) => {
  context.commit(types.SET_TENANTS, tenants)
}

export const handleRedirectAfterAuthentication = async (
  context: ActionContext<AuthState, RootState>,
  showOnboarding = false
) => {
  if (context.state.redirectAfterLogin) {
    try {
      await router.replace({
        name: 'Private'
      })

      await privateRouter.replace({ path: context.state.redirectAfterLogin })
    } catch (error) {
      console.error(error)
    }

    return
  }

  await context.dispatch('defaultRouteRedirect', showOnboarding)
}

export const emailLogin = async (
  context: ActionContext<AuthState, RootState>,
  authPayload: AuthPayload
) => {
  const { userName, password, tenantId } = authPayload

  if (userName && password) {
    const nucleusService = new NucleusService()

    const authResponse = await nucleusService.emailAuth({
      userName,
      password,
      tenantId
    })

    if (authResponse.passwordExpired)
      context.dispatch(`setPasswordExpired`, authResponse.passwordExpired)

    return await context.dispatch('handleAuthResponse', authResponse)
  }
}

export const legacyLogin = async (
  context: ActionContext<AuthState, RootState>,
  token: string
) => {
  const nucleusService = new NucleusService()

  try {
    const authResponse = await nucleusService.legacyAuth(token)

    return await context.dispatch('handleAuthResponse', authResponse)
  } catch (err) {
    throw new Error('Error at legacyLogin: ' + err)
  }
}

export const googleLogin = async (
  context: ActionContext<AuthState, RootState>,
  authPayload: AuthPayload
) => {
  const nucleusService = new NucleusService()

  try {
    const authResponse = await nucleusService.googleAuth(authPayload)

    return await context.dispatch('handleAuthResponse', authResponse)
  } catch (err) {
    throw new Error('Error at googleLogin: ' + err)
  }
}

export const linkedinLogin = async (
  context: ActionContext<AuthState, RootState>,
  linkedinPayload: LinkedinPayload
) => {
  const nucleusService = new NucleusService()

  const authResponse = await nucleusService.linkedinAuth(linkedinPayload)

  return context.dispatch('handleAuthResponse', authResponse)
}

export const handleSSOLogin = async (
  context: ActionContext<AuthState, RootState>,
  payload: SSOPayload
) => {
  const { tenantId, queryString } = payload
  const nucleusService = new NucleusService()

  const authResponse = await nucleusService.loginSSO(tenantId, queryString)
  await context.dispatch('handleAuthResponse', authResponse)
  return authResponse
}

export const checkSignupParameters = async (
  context: ActionContext<AuthState, RootState>,
  signupPayload: SignupPayload
) => {
  signupPayload.miniCluster = true

  const coupon = await context.dispatch('getCoupon')

  if (coupon) signupPayload.coupons = [coupon]

  const feature = await context.dispatch('getFeature')

  if (feature) signupPayload.features = [feature]

  const partner = await context.dispatch('getPartner')

  if (partner) signupPayload.configs.partner = partner

  if (getParameterByName('features') === 'chat') {
    signupPayload.features = ['sales']
  }

  return signupPayload
}

export const saveUrlInfo = async (
  context: ActionContext<AuthState, RootState>
) => {
  await context.dispatch('getCoupon')

  await context.dispatch('getFeature')

  await context.dispatch('getPartner')
}

export const emailSignup = async (
  context: ActionContext<AuthState, RootState>,
  signupPayload: SignupPayload
) => {
  const nucleusService = new NucleusService()

  const authResponse = await nucleusService.emailSignup(signupPayload)

  return context.dispatch('handleAuthResponse', authResponse)
}

export const signupAppend = async (_: any, signupPayload: SignupPayload) => {
  const nucleusService = new NucleusService()

  await nucleusService.signupAppend(signupPayload)
}

export const googleSignup = async (
  context: ActionContext<AuthState, RootState>,
  signupPayload: SignupPayload
) => {
  const nucleusService = new NucleusService()

  const authResponse = await nucleusService.googleSignup(signupPayload)

  return context.dispatch('handleAuthResponse', authResponse)
}

export const trayAuth = async (
  context: ActionContext<AuthState, RootState>,
  signupPayload: SignupPayload
) => {
  const nucleusService = new NucleusService()

  return nucleusService.trayAuth(signupPayload)
}

export const trayAuthProcess = async (
  context: ActionContext<AuthState, RootState>,
  signupPayload: SignupPayload
) => {
  try {
    const authResponse = await context.dispatch('trayAuth', signupPayload)

    if (authResponse.status && authResponse.status !== STATUS_CODE.OK) {
      return authResponse
    } else {
      await context.dispatch('handleAuthResponse', authResponse)

      setCookie('is-whitelabel', 'tray')

      await context.dispatch('identifyAppcuesUser', {
        isWhitelabel: 'tray'
      })

      if (store.getters.isTrayWhitelabel) {
        analytics(
          EVENTS.WHITELABEL_SIGNUP_SUCCESS,
          {
            target: PRODUCT_TARGETS.WHITELABEL_TRAY
          },
          { engine: ['segment'] }
        )
      }
      await context.dispatch('handleRedirectAfterAuthentication')
    }
  } catch (ex) {
    console.error(ex)

    await context.dispatch('trackEvent', {
      name: 'signup failed',
      sendToGa: true,
      new_analytics: true,
      fail_reason: ex
    })
    return ex
  }
}

export const trayAdmins = async (
  context: ActionContext<AuthState, RootState>,
  storeId: string
) => {
  const nucleusService = new NucleusService()

  return nucleusService.trayAdmins(storeId)
}

export const installTrayWidget = (
  context: ActionContext<AuthState, RootState>,
  isNewWidget?: boolean
): Promise<void> => {
  const nucleusService = new NucleusService()

  return nucleusService.installTrayWidget(isNewWidget)
}
export const removeTrayWidget = (): Promise<void> => {
  const nucleusService = new NucleusService()

  return nucleusService.removeTrayWidget()
}

export const verifyEmail = async (
  context: ActionContext<AuthState, RootState>,
  email: string
): Promise<boolean> => {
  const payload: HubspotFormPayload = {
    fields: [
      {
        name: 'email',
        value: email
      }
    ],
    context: {
      pageUri: window.location.href,
      pageName: ''
    }
  }

  const utms = getUtmValues()

  if (utms) {
    for (const utm in utms) {
      const value = utms[utm]

      if (value) {
        payload.fields.push({
          name: utm,
          value
        })
      }
    }
  }

  const cookie = getCookie('hubspotutk')

  if (cookie) {
    payload.context.hutk = cookie
  }

  try {
    return await context.dispatch('submitHubspotForm', payload)
  } catch (ex) {
    return false
  }
}

export const verifyAccount = async (
  _: any,
  verifyAccountPayload: VerifyAccountPayload
): Promise<any> => {
  const nucleusService = new NucleusService()

  return await nucleusService.verifyAccount(verifyAccountPayload)
}

export const requestAccess = async (
  _: any,
  requestAccessPayload: AccessRequest
) => {
  const nucleusService = new NucleusService()

  return await nucleusService.requestAccess(requestAccessPayload)
}

export const acceptAccessRequest = async (
  _: any,
  accessRequestResponse: AccessRequestResponse
) => {
  const nucleusService = new NucleusService()

  return await nucleusService.acceptAccessRequest(accessRequestResponse)
}

export const declineAccessRequest = async (
  _: any,
  accessRequestResponse: AccessRequestResponse
) => {
  const nucleusService = new NucleusService()

  return await nucleusService.declineAccessRequest(accessRequestResponse)
}

export const requestNewPassword = async (_: any, email: string) => {
  const nucleusService = new NucleusService()

  try {
    return await nucleusService.requestNewPassword(email)
  } catch (error) {
    if (error instanceof APIError) {
      throw new AuthError(error)
    }

    throw error
  }
}

export const inviteAgent = async (
  context: ActionContext<AuthState, RootState>,
  inviteAgentPayload: InviteAgentPayload
) => {
  const nucleusService = new NucleusService()

  return await nucleusService.inviteAgent(inviteAgentPayload)
}

export const createNewPassword = async (
  _: any,
  newPasswordPayload: NewPasswordPayload
) => {
  const nucleusService = new NucleusService()

  return await nucleusService.createNewPassword(newPasswordPayload)
}

export const changePassword = async (
  _: any,
  changePasswordPayload: ChangePasswordPayload
) => {
  const nucleusService = new NucleusService()

  return await nucleusService.changePassword(changePasswordPayload)
}

export const setRedirectAfterLogin = (
  context: ActionContext<AuthState, RootState>,
  url: string
) => {
  context.commit(types.SET_REDIRECT_AFTER_LOGIN, url)
}

export const setKeepConnect = (
  context: ActionContext<AuthState, RootState>,
  value: boolean
) => {
  context.commit(types.SET_KEEP_CONNECTED, value)
}

export const checkEnvironmentId = async (
  context: ActionContext<AuthState, RootState>,
  status: SubDomainStatus
) => {
  if (Object.keys(status).length == 0) return

  const storageAuth = Storage.getItem('auth') as AuthState

  if (storageAuth && storageAuth.octaAuthenticated && status) {
    const differentIds =
      status.environmentId !== storageAuth.octaAuthenticated.environmentId

    if (differentIds) logout(context)
  }
}

export const getStatus = async (
  context: ActionContext<AuthState, RootState>,
  path: string
) => {
  const userService = new UsersService()

  const nucleusStatus = await userService.getStatus(path)

  const status = {
    ...nucleusStatus
  }

  checkEnvironmentId(context, status)

  context.commit(types.SET_SUBDOMAIN_STATUS, status)

  if (status.isBlocked) {
    await context.dispatch('setPendingPaymentModal', true)
  } else if (status.isTrialExpired && !context.getters.isTrayWhitelabel) {
    await context.dispatch('setTrialOverModal', true)
  }

  await context.dispatch('setFeaturesToggle')
}

export const setSubDomainStatus = (
  context: ActionContext<AuthState, RootState>,
  status: SubDomainStatus
) => {
  context.commit(types.SET_SUBDOMAIN_STATUS, status)
}

export const setUserLogged = (
  context: ActionContext<AuthState, RootState>,
  userLogged: UserLogged
) => {
  if (userLogged) {
    userLogged['organizations'] = []

    Storage.setItem('userLogged', userLogged)

    Storage.setItem('company', userLogged.subDomain)

    context.commit(types.SET_USER_LOGGED, userLogged)
  } else {
    context.commit(types.SET_USER_LOGGED, getUserLogged())
  }
}

export const setUserToken = (
  context: ActionContext<AuthState, RootState>,
  userToken: string
) => {
  if (userToken) {
    Storage.setItem('userToken', userToken)

    context.commit(types.SET_USER_TOKEN, userToken)
  } else {
    context.commit(types.SET_USER_TOKEN, Storage.getItem('userToken'))
  }
}

export const setAuth = (
  context: ActionContext<AuthState, RootState>,
  auth: AuthState
) => {
  const { keepConnected } = context.state
  if (keepConnected) {
    if (auth && auth.access_token) {
      Storage.setItem('auth', auth)

      Storage.setItem('nucleusTenantUser', {
        firstName: auth.octaAuthenticated?.firstName,
        lastName: auth.octaAuthenticated?.lastName
      })
    } else {
      Storage.removeItem('auth')
    }
  } else {
    if (auth && auth.access_token) {
      sessionStorage.setItem('auth', JSON.stringify(auth))

      Storage.setItem('nucleusTenantUser', {
        firstName: auth.octaAuthenticated?.firstName,
        lastName: auth.octaAuthenticated?.lastName
      })
    } else {
      sessionStorage.removeItem('auth')
    }
  }

  const languageCode = auth.octaAuthenticated?.languageCode

  if (languageCode) {
    setCulture(languageCode)
  }

  context.commit(types.SET_AUTH, auth)
}

export const clearUserLogged = (
  context: ActionContext<AuthState, RootState>
) => {
  Storage.removeItem('userLogged')

  Storage.removeItem('company')

  context.commit('SET_USER_LOGGED', undefined)
}

export const clearUserToken = (
  context: ActionContext<AuthState, RootState>
) => {
  Storage.removeItem('userToken')

  context.commit('SET_USER_TOKEN', undefined)
}

export const clearSubDomainStatus = (
  context: ActionContext<AuthState, RootState>
) => {
  Storage.removeItem('status')

  context.commit('SET_SUBDOMAIN_STATUS', undefined)
}

export const formatUtmsToUrl = (utms: Record<string, any> | boolean) => {
  const defaultUtms: Record<string, string> = {
    utm_source: '',
    utm_medium: '',
    utm_term: '',
    utm_content: '',
    utm_campaign: ''
  }

  const newUtms: Array<Record<string, string>> = []

  Object.entries(utms).forEach(utm => {
    newUtms.push({ name: utm[0], value: utm[1] })
  })

  return newUtms || defaultUtms
}

export const getIdOctadesk = async (
  context: ActionContext<AuthState, RootState>,
  subDomain: string
) => {
  const { octaAuthenticated } = context.getters

  if (octaAuthenticated?.environmentId && subDomain.indexOf('-') > -1) {
    const idNucleus = subDomain
      .substring(0, subDomain.indexOf('-'))
      .replace(/[^0-9]/g, '')

    context.commit(types.SET_ID_OCTADESK, parseInt(idNucleus))

    return parseInt(idNucleus)
  } else {
    const coreService = new CoreService()

    const idOctadesk = await coreService.getIdOctadesk(subDomain)

    context.commit(types.SET_ID_OCTADESK, idOctadesk)

    return idOctadesk
  }
}

export const setLoginType = (
  context: ActionContext<AuthState, RootState>,
  loginType: string
) => {
  context.commit(types.SET_LOGIN_TYPE, loginType)
}

export const setLoginTypeSSO = (
  context: ActionContext<AuthState, RootState>
) => {
  const loginType = context.state.loginInfo?.type
  context.commit(types.SET_LOGIN_TYPE, SignupUserTypes.SSO)
  context.commit(types.SET_PREV_LOGIN_TYPE, loginType)
}

export const resetSSOLoginType = (
  context: ActionContext<AuthState, RootState>
) => {
  const prevLoginType = context.state.prevLoginType
  context.commit(types.SET_LOGIN_TYPE, prevLoginType)
}

export const setTenantId = (
  context: ActionContext<AuthState, RootState>,
  tenantId: string
) => {
  context.commit(types.SET_TENANT_ID, tenantId)
}

export const setEmail = (
  context: ActionContext<AuthState, RootState>,
  email: string
) => {
  context.commit(types.SET_EMAIL, email)
}

export const setEmailLoginInfo = (
  context: ActionContext<AuthState, RootState>,
  userInfo: any
) => {
  context.commit(types.SET_EMAIL_LOGIN_INFO, userInfo)
}

export const setGoogleToken = (
  context: ActionContext<AuthState, RootState>,
  loginType: string
) => {
  context.commit(types.SET_GOOGLE_TOKEN, loginType)
}

export const setLegacyLoginInfo = (
  context: ActionContext<AuthState, RootState>,
  token: string
) => {
  context.commit(types.SET_LEGACY_LOGIN_INFO, token)
}

export const handleLogin = async (
  context: ActionContext<AuthState, RootState>
) => {
  const { type, email, password, tenantId, token } = context.state.loginInfo!

  try {
    if (type === 'legacy') {
      return await context.dispatch('legacyLogin', token)
    } else if (type === 'email') {
      const emailPayload = {
        userName: email,
        password: password,
        tenantId: tenantId
      }

      return await context.dispatch('emailLogin', emailPayload)
    } else {
      const googlePayload = {
        idToken: token,
        tenantId: tenantId
      }

      return await context.dispatch('googleLogin', googlePayload)
    }
  } catch (ex) {
    context.dispatch('showLoader', false)

    if (ex instanceof APIError) {
      throw new AuthError(ex)
    }

    throw new Error((ex as Error).message)
  }
}
export const getCompanyModel = async (
  context: ActionContext<AuthState, RootState>
) => {
  const companyService = new CompanyService()

  const model = await companyService.getModel()

  if (model) {
    context.commit(types.SET_COMPANY_MODEL, model)
  }
}

export const updateApisLocations = async (): Promise<OctaConfig> => {
  return octaConfig()
}

export const defaultRouteRedirect = async (
  context: ActionContext<AuthState, RootState>,
  showOnboarding: boolean
) => {
  const isLogged = await context.dispatch('checkAuthState')

  if (!isLogged) {
    router
      .replace({
        name: 'Public'
      })
      .catch(() => {})

    publicRouter
      .replace({
        path: '/login'
      })
      .catch(() => {})

    return
  }

  await router.replace({ name: 'Private' }).catch(() => {})

  const passwordExpired =
    context.getters.hasToChangePassword?.toString() ??
    Storage.getItem('passwordExpired')

  Storage.setItem('passwordExpired', passwordExpired)

  if (context.getters.isTrayWhitelabel) {
    if (!context.getters.trayWidget && context.getters.isAdmin) {
      privateRouter.push({ name: 'settings.tray' }).catch(() => {})
    } else {
      privateRouter.push({ name: 'chat' }).catch(() => {})
    }
  } else if (context.getters.hasToChangePassword) {
    privateRouter.push({
      name: 'change-password'
    })
  } else if (showOnboarding) {
    privateRouter.push({ name: 'onboarding' }).catch(() => {})
  } else if (privateRouter.currentRoute.path !== '/') {
    privateRouter.replace({ path: '/' }).catch(() => {})
  }

  context.dispatch('showLoader', false)
}

export const isVuetiful = () => resolveQueryStringParameter('Vuetiful')

export const isNewOcta = (context: ActionContext<AuthState, RootState>) =>
  context.getters.octaAuthenticated?.newOcta

export const getCoupon = () => {
  const paramValue = getParameterByName('c')

  if (paramValue != undefined && paramValue != '') {
    setCookie('coupon', paramValue)

    return paramValue
  }

  return getCookie('coupon')
}

export const getFeature = () => {
  const paramValue = getParameterByName('f')

  if (paramValue != undefined && paramValue != '') {
    setCookie('feature', paramValue)

    return paramValue
  }

  return getCookie('feature')
}

export const getPartner = () => {
  const paramValue = getParameterByName('p')

  if (paramValue != undefined && paramValue != '') {
    setCookie('partner', paramValue)

    return paramValue
  }

  return getCookie('partner')
}

export const socketAgentConnectionStatus = async (
  context: ActionContext<AuthState, RootState>,
  agentStatus: any
) => {
  const { id: userLoggedId } = context.rootGetters.userLogged

  if (userLoggedId === agentStatus.idAgent) {
    context.commit(types.SET_CONNECTION_STATUS, agentStatus.status)
  }
}

export const getAgentConnectionStatus = async (
  context: ActionContext<AuthState, RootState>
) => {
  const { id } = context.getters.userLogged

  const { connectionStatus } = await new ChatAgentsService().getAgent(id)

  if (typeof connectionStatus === 'undefined') {
    throw new Error(
      'Error at getAgentConnectionStatus: missing connectionStatus'
    )
  }

  context.commit(types.SET_CONNECTION_STATUS, connectionStatus)
}

export const getCurrentAgentAppsIds = async (
  context: ActionContext<AuthState, RootState>
) => {
  const { myApps } = context.getters.userLogged

  if (typeof myApps === 'undefined')
    throw new Error('Error at getCurrentAgentAppsIds: missing myApps')

  context.commit(types.SET_CURRENT_AGENT_APPS_IDS, myApps)
}

export const setLoggedAgentConnectionStatus = async (
  context: ActionContext<AuthState, RootState>,
  status: ChatConnectionStatus
) => {
  const { id } = context.getters.userLogged

  const previousStatus = context.getters.connectionStatus

  context.commit(types.SET_CONNECTION_STATUS, status)

  const chatAgentsService = new ChatAgentsService()

  await chatAgentsService.setAgentStatus(status, id).catch(e => {
    context.commit(types.SET_CONNECTION_STATUS, previousStatus)

    const actualStatusKey = getEnumKeyByEnumValue(ChatConnectionStatus, status)

    const previousStatusKey = getEnumKeyByEnumValue(
      ChatConnectionStatus,
      previousStatus
    )

    context.dispatch('dispatchAlert', {
      variant: 'danger',
      title: i18n?.t('sidebar.menu.status.alerts.danger.title', [
        i18n?.t(`sidebar.menu.status.${actualStatusKey}`),
        i18n?.t(`sidebar.menu.status.${previousStatusKey}`)
      ])
    })

    console.error(
      `Error on setLoggedAgentConnectionStatus. Request error: ${e}`
    )
  })
}

export const setAllAgentConnectionStatus = async (
  _: ActionContext<AuthState, RootState>,
  { status, ids }: { status: ChatConnectionStatus; ids: string[] }
) => {
  const chatAgentsService = new ChatAgentsService()

  return chatAgentsService.setAllAgentStatus(status, ids)
}

export const setAgentConnectionStatus = async (
  context: ActionContext<AuthState, RootState>,
  { status, id }: { status: ChatConnectionStatus; id: string }
) => {
  return setAllAgentConnectionStatus(context, { status, ids: [id] })
}

const notOnBillingRoute = () => {
  return !window.location.href.includes(`/billing`)
}

export const setTrialOverModal = (
  context: ActionContext<AuthState, RootState>,
  trialOverModal: boolean
) => {
  if (notOnBillingRoute())
    context.commit(types.SET_TRIAL_OVER_MODAL, trialOverModal)
}

export const setPendingPaymentModal = (
  context: ActionContext<AuthState, RootState>,
  pendingPaymentModal: boolean
) => {
  if (notOnBillingRoute())
    context.commit(types.SET_PENDING_PAYMENT_MODAL, pendingPaymentModal)
}

export const getInviteAgentParameters = () => {
  const user = getParameterByName('u')

  const token = getParameterByName('t')

  return [user, token]
}

export const setMigrated = async (
  context: ActionContext<AuthState, RootState>,
  migratedEnvironment: boolean
) => {
  context.commit(types.SET_MIGRATED_ENVIRONMENTED, migratedEnvironment)
}

export const getTenantConfigs = async (
  context: ActionContext<AuthState, RootState>
) => {
  const { tenantId } = context.getters

  const nucleusService = new NucleusService()

  const configs = await nucleusService.getTenantConfigs(tenantId)

  context.commit(types.SET_TENANT_CONFIGS, configs)
}

export const setTenantConfigs = async (
  context: ActionContext<AuthState, RootState>,
  config: Record<string, string>
) => {
  const { tenantId } = context.getters

  const nucleusService = new NucleusService()

  const configAlreadySelected = context.getters.getTenantConfigByValue(
    config.code
  )

  let existingConfigId = null

  if (configAlreadySelected) {
    existingConfigId = configAlreadySelected.id
  }

  await nucleusService.updateTenantConfigs(tenantId, config, existingConfigId)

  await context.dispatch('getTenantConfigs')
}

export const sendAppcuesData = async (
  context: ActionContext<AuthState, RootState>
) => {
  const userLogged = context.state.userlogged

  const subDomainStatus = context.state.subDomainStatus

  const appcuesData = {
    name: userLogged?.name,
    email: userLogged?.email,
    datecreation: userLogged?.dateCreation,
    subdomain: userLogged?.subDomain,
    type: userLogged?.type,
    roletype: userLogged?.roleType,
    permissiontype: userLogged?.permissionType,
    permissionview: userLogged?.permissionView,
    participantpermission: userLogged?.participantPermission,
    lastlogin: userLogged?.lastLogin,
    dayssincedatecreation: userLogged?.daysSinceDateCreation,
    dayssincelastlogin: userLogged?.daysSinceLastLogin,
    is_trial: subDomainStatus?.isTrial
  }

  await context.dispatch('identifyAppcuesUser', appcuesData)
}

export const setPasswordExpired = async (
  context: ActionContext<AuthState, RootState>,
  passwordExpired: boolean
) => {
  Storage.setItem('passwordExpired', passwordExpired.toString())

  context.commit(types.SET_PASSWORD_EXPIRED, passwordExpired)
}

export const setSSOTenants = async (
  context: ActionContext<AuthState, RootState>,
  tenants: Array<SSOTenant>
) => {
  context.commit(types.SET_SSO_TENANTS, tenants)
}
